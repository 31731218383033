<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen">
      <StepsComponent :step="2" />
      <div class="tw-relative tw-min-h-screen">
        <TitleComponent class="tw-mt-8" :text="title" />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
          Encore un ultime effort !<br />
          Conformément à notre <v-btn text class="tw-underline btn-link-text tw-cursor-pointer" @click="openPolitique">
            charte de confidentialité</v-btn>, nous avons besoin de votre consentement pour :
        </SignupSecondaryTextComponent>
        <v-form ref="form">
          <div class="tw-w-8/12 tw-m-auto tw-mt-12">
            <SwitchButtonComponent v-model="notifsAccepted" @change="onNotifsSwitchChange"
              label="Utiliser les données renseignées pour vous notifier et vous tenir informé de l’activité." />
            <SwitchButtonComponent v-model="cookiesAccepted" @change="onCookiesSwitchChange"
              label="Utiliser des cookies dits de confort qui vous permettront d’accéder plus facilement à votre compte." />
            <div class="public-text-register-step3 tw-mt-4">
              Vous déclarez avoir lu et accepté les
              <v-btn text class="tw-underline btn-link-text tw-cursor-pointer" @click="modalitiesDialog = true">
                modalités</v-btn> et les
              <v-btn text class="tw-underline btn-link-text tw-cursor-pointer" @click="cguDialog = true">conditions
                générales d’utilisation</v-btn>
              de la plateforme.
            </div>
            <div class="tw-flex tw-flex-col tw-my-8 tw-items-center">
              <ReadConditionsModalComponent type="MDT" btn-class="tw-w-64 tw-mb-4" btn-title="Lire les modalités"
                title="Lire les modalités" :dialog="modalitiesDialog" @closeEvent="closeModalsFromChild"
                @openEvent="openModalsFromChild" @acceptConditions="modalitiesWasAccepted = true"
                :conditionsWasAccepted="modalitiesWasAccepted">
                <MentionsLegalesComponent />
              </ReadConditionsModalComponent>
              <ReadConditionsModalComponent type="CGU" btn-class="tw-w-64" btn-title="Lire les CGU" title="CGU"
                :dialog="cguDialog" @closeEvent="closeModalsFromChild" @openEvent="openModalsFromChild"
                @acceptConditions="cgeWasAccepted = true" :conditionsWasAccepted="cgeWasAccepted">
                <CGUComponent />
              </ReadConditionsModalComponent>
            </div>
          </div>
          <div class="tw-flex tw-justify-center tw-pb-20 tw-space-x-4">
            <PrimaryButtonComponent @click="modalitiesDialog = true">
              Lire les modalités
            </PrimaryButtonComponent>
            <PrimaryButtonComponent @click="cguDialog = true">
              Lire les CGU
            </PrimaryButtonComponent>
          </div>
          <div class="tw-flex tw-justify-between tw-mt-14 tw-pb-20">
            <SecondaryButtonComponent @click="onBack">
              <v-icon left dark> mdi-arrow-left </v-icon>Précédent
            </SecondaryButtonComponent>
            <PrimaryButtonComponent :loading="isSendingForm" @click="onSubmit" :disabled="!formValid || isSendingForm">
              Suivant
              <v-icon right dark> mdi-arrow-right </v-icon>
            </PrimaryButtonComponent>
          </div>

        </v-form>
        <footer-paragraph-text />
      </div>
      <PolitiquePopup @close="closePolitique" :openPopup="openPopupPolitique" />
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";

import SecondaryButtonComponent from "@/components/common/SecondaryButtonComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import SwitchButtonComponent from "@/components/common/SwitchButtonComponent";
import ReadConditionsModalComponent from "@/features/auth/components/ReadConditionsModalComponent";
import { validationRules } from "@/utils/validation-rules";
import { CollaboratorLastStepSignUpApi } from "@/features/auth/services/appApi";
import MentionsLegalesComponent from "@/components/layout/landing/MentionsLegalesComponent";
import CGUComponent from "@/features/company/components/Dashboard/CGUComponent";
import PolitiquePopup from "@/components/layout/landing/PolitiquePopup";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";
import { GetCollaboratorFirstStepSignUpApi } from "@/features/auth/services/appApi";

function validateForm() {
  this.formValid =
    this.notifsAccepted &&
    this.cookiesAccepted &&
    this.modalitiesWasAccepted &&
    this.cgeWasAccepted;
}

export default {
  components: {
    PolitiquePopup,
    CGUComponent,
    StepsComponent,
    MentionsLegalesComponent,
    ReadConditionsModalComponent,
    SwitchButtonComponent,
    SecondaryButtonComponent,
    PrimaryButtonComponent,
    TitleComponent,
    SignupSecondaryTextComponent
  },
  data() {
    return {
      title: null,
      openPopupPolitique: false,
      formSent: false,
      formValid: false,
      isLoadingData: true,
      isSendingForm: false,
      notifsAccepted: false,
      cookiesAccepted: false,
      modalitiesWasAccepted: false,
      cgeWasAccepted: false,
      modalitiesDialog: false,
      cguDialog: false,
      rules: {
        notifsAccepted: [validationRules.required],
      },

    };
  },

  methods: {
    openPolitique() {
      this.openPopupPolitique = true;
    },
    closePolitique() {
      this.openPopupPolitique = false;
    },
    onBack() {
      this.$router.push({ name: 'signupSecond', params: { token: this.$route.params.token } });
    },
    onNotifsSwitchChange: function (value) {
      this.notifsAccepted = value;
    },
    onCookiesSwitchChange: function (value) {
      this.cookiesAccepted = value;
    },
    onSubmit() {
      this.isSendingForm = true;
      CollaboratorLastStepSignUpApi(this.$route.params.token)
        .then(() => {
          this.isSendingForm = false;
          this.formSent = true;
          window.scrollTo(0, 0);
          this.$router.push({
            name: "collaborator.signup.thirdStep",
            params: { token: this.$route.params.token },
          });
        })
        .catch(({ response: { data } }) => {
          window.scrollTo(0, 0);
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: data.message || "Erreur: quelque chose s'est mal passé",
            type: "error",
          });
        });
    },

    go_to_login() {
      this.$router.push({ name: 'LoginPage' })
    },

    openModalsFromChild(item) {
      if (item === 'CGU') {
        this.cguDialog = true;
      }
      if (item === 'MDT') {
        this.modalitiesDialog = true;
      }
    },

    closeModalsFromChild() {
      this.cguDialog = false;
      this.modalitiesDialog = false;
    }
  },
  watch: {
    notifsAccepted: validateForm,
    cookiesAccepted: validateForm,
    modalitiesWasAccepted: validateForm,
    cgeWasAccepted: validateForm,
  },
  mounted() {
    GetCollaboratorFirstStepSignUpApi(this.$route.params.token).then((res) => {
      this.title = `${res.data.data.workInCompany.socialReason}, recueil du consentement, acceptation des modalités et CGU`;
      this.isLoadingData = false;
    });
  },
};
</script>

<style scoped>

</style>
